@import '../../styles/variables.css';

.container {
  display: block;
  width: 121px;
  height: 23px;

  @media (--tablet-landscape-up) {
    width: 139px;
    height: 27px;
  }
}

.logo {
  display: block;
  width: 100%;
  height: 100%;
  fill: var(--header-logo-color);

  path,
  polygon {
    transition: fill 0.2s;
  }
}
