@import '../../styles/variables.css';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  padding: 0 var(--container-padding);
  background-color: var(--black);
}

.logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-default);
}

.info {
  @mixin font-family-title;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);

  h1 {
    margin-bottom: 35px;
    @mixin font-boldest;
    font-size: 30px;
    line-height: 100%;

    @media (--tablet-landscape-up) {
      font-size: 46px;
    }
  }

  p {
    margin-bottom: 40px;
    @mixin font-thin;
    font-size: 18px;
    line-height: 26px;

    @media (--tablet-landscape-up) {
      font-size: 24px;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    @mixin font-medium;
    font-size: 14px;
    line-height: 100%;

    @media (--tablet-landscape-up) {
      font-size: 18px;
    }

    a {
      display: inline;
      position: relative;
      margin-left: 5px;
      color: inherit;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 5px);
        height: 1px;
        width: 100%;
        background-color: var(--white);
      }
    }
  }
}
